/**
 * Get the ISO code of a language

 * Enter the index of the current language related
 * to app language [0 = "English", 1 = "French", ...]
 *
 * @param {int} index - the index of correspondant language
 * @return {string} - return the two letter language and ISO
 */
export const getLangISOByIndex = (index) => {
	const ISOCodes = {
		0: { two: 'en', iso: 'en-US' },
		1: { two: 'fr', iso: 'fr-FR' },
		2: { two: 'de', iso: 'de-DE' },
		3: { two: 'it', iso: 'it-IT' },
		4: { two: 'nl', iso: 'nl-NL' },
		5: { two: 'es', iso: 'es-ES' },
		6: { two: 'pt', iso: 'pt-BR' },
		7: { two: 'sk', iso: 'sk-SK' }
	};

	if (index > 7) index = 0;

	return ISOCodes[index];
};
