/**
 * @package     Qwerteach front
 * @element     MapFilter Helper
 * @author      Maxime Bartier <maxime.bartier@qwerteach.com>
 *
 * @inspired    https://github.com/remyhunt/vue-map-filters
 */

import { isNil } from '~utils';

/**
 * @param {Array} filters
 * @return {Array}
 */
export function mapFilters(filters) {
	if (isNil(filters)) return;
	return filters.reduce((result, filter) => {
		result[filter] = function (...args) {
			if (isNil(this.$options.filters)) throw new Error('No filters');
			return this.$options.filters[filter](...args);
		};
		return result;
	}, {});
}
