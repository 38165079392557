<template>
	<div class="start-end-time-over-range--component-wrapper full">
		<b-row>
			<b-col v-if="showCurrentDate" md="3">
				<div style="padding-top: 20px">
					<b-form-input :disabled="!activeDate || disabled" :value="rangePayload.date | formatDate" @update="handleDateChange" />
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col :cols="!hideEndDate ? 6 : 12">
				<b-form-group :label="rendStartLabel">
					<b-form-input
						type="time"
						:disabled="disableSave || disabled"
						:value="rendFormattedTime(rangePayload.start, 'start')"
						required
						:state="stateStartDate"
						@update="handleTimeChange($event, 'start')"
					/>
				</b-form-group>
			</b-col>
			<b-col v-if="!hideEndDate" cols="6">
				<b-form-group :label="rendEndLabel">
					<b-form-input
						type="time"
						:disabled="disableSave || disabled"
						:value="rendFormattedTime(rangePayload.end, 'end')"
						required
						:state="stateEndDate"
						@input="handleTimeChange($event, 'end')"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row v-if="!multipleSelectionDate">
			<b-col v-if="!hideBefore" :cols="!hideEndDate ? 6 : 12">
				<div v-if="!hideStartDate">
					<div class="input-date-number-iteration">
						<b-button
							:disabled="isStartSubtractDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('start', 'subtract')"
						>
							<b-icon icon="chevron-left" />
						</b-button>
						<span class="value" style="font-size: 10px !important; font-weight: 600">
							{{ labelBeforeDate('start') }}
							<span v-if="!hideLabelDate" style="font-size: 10px !important; font-weight: 600">
								- {{ rangePayload.start | formatDayMonth('DD MMMM') }}</span
							>
						</span>
						<b-button
							:disabled="isEndAddDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('start', 'add')"
						>
							<b-icon icon="chevron-right" />
						</b-button>
					</div>
				</div>
			</b-col>
			<b-col v-if="!hideAfter" cols="6">
				<div v-if="!hideEndDate">
					<div class="input-date-number-iteration">
						<b-button
							:disabled="isEndSubtractDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('end', 'subtract')"
						>
							<b-icon icon="chevron-left" />
						</b-button>
						<span class="value" style="font-size: 10px !important; font-weight: 600">
							{{ labelBeforeDate('end') }}
							<span v-if="!hideLabelDate" style="font-size: 10px !important; font-weight: 600">
								- {{ rangePayload.end | formatDayMonth('DD MMMM') }}</span
							>
						</span>
						<b-button
							:disabled="isEndAddDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('end', 'add')"
						>
							<b-icon icon="chevron-right" />
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="(multipleSelectionDate && isSingleSelectOnPlanning) || (days !== undefined && days.length === 1)">
			<b-col v-if="!hideBefore" :cols="!hideEndDate ? 6 : 12">
				<div v-if="!hideStartDate">
					<div class="input-date-number-iteration">
						<b-button
							:disabled="isStartSubtractDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('start', 'subtract')"
						>
							<b-icon icon="chevron-left" />
						</b-button>
						<span class="value" style="font-size: 10px !important; font-weight: 600">
							{{ labelBeforeDate('start') }}
							<span style="font-size: 10px !important; font-weight: 600"> - {{ rangePayload.start | formatDayMonth('DD MMMM') }}</span>
						</span>
						<b-button
							:disabled="isEndAddDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('start', 'add')"
						>
							<b-icon icon="chevron-right" />
						</b-button>
					</div>
				</div>
			</b-col>
			<b-col v-if="!hideAfter" cols="6">
				<div v-if="!hideEndDate">
					<div class="input-date-number-iteration">
						<b-button
							:disabled="isEndSubtractDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('end', 'subtract')"
						>
							<b-icon icon="chevron-left" />
						</b-button>
						<span class="value" style="font-size: 10px !important; font-weight: 600">
							{{ labelBeforeDate('end') }}
							<span style="font-size: 10px !important; font-weight: 600"> - {{ rangePayload.end | formatDayMonth('DD MMMM') }}</span>
						</span>
						<b-button
							:disabled="isEndAddDisabled || disabled"
							size="sm"
							style="background: transparent; border: none; color: #225cbd"
							@click="handleChangeDay('end', 'add')"
						>
							<b-icon icon="chevron-right" />
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import moment from 'moment';
import mapProps from '@/shared/vuePropsMapper';
import { isNil } from '@/shared/utils';
import { mapFilters } from '@/shared/map-filters';
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

const today = new Date();
const currDate = moment(today).format('YYYY-MM-DD');

export default {
	name: 'StartEndTimeOverRangeComponent',
	mixins: [languageMessages],
	props: {
		days: {
			type: Array,
			required: false
		},
		...mapProps(
			[
				'showCurrentDate',
				'disableSave',
				'hideBefore',
				'hideAfter',
				'activeDate',
				'isLimitedByInitDate',
				'endCanGoBeforeStartDate', // todo on est quand même bloqué
				'hideStartDate',
				'hideEndDate',
				'syncStartEnd',
				'hideLabelDate'
			],
			{
				type: Boolean,
				required: false,
				default: false
			}
		),
		...mapProps(['startLabel', 'endLabel'], {
			type: String,
			required: false,
			default: null
		}),
		...mapProps(['dayDurationLimit', 'startForwardDay', 'startBackwardDay', 'endBackwardDay', 'endForwardDay'], {
			type: Number,
			required: false,
			default: 1
		}),
		value: {
			type: Object,
			required: false,
			default: null
		},
		stateStartDate: {
			type: Boolean,
			required: false,
			default: null
		},
		stateEndDate: {
			type: Boolean,
			required: false,
			default: null
		},
		disabled: {
			type: Boolean,
			required: false
		},
		multipleSelectionDate: {
			type: Boolean,
			default: false,
			required: false
		},
		dataOneSelect: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			rangeStatus: { start: 'current', end: 'current' },
			rangePayload: {
				start: '08:00',
				end: '18:00',
				date: currDate
			},
			oppTypes: { start: 'end', end: 'start' },

			checkStartSubstractWhenHideEndDate: false,
			checkStartAddWhenHideEndDate: false,
			startIsNull: false,
			endIsNull: false
		};
	},
	computed: {
		isSingleSelectOnPlanning() {
			if (this.dataOneSelect !== undefined) {
				let allData = this.dataOneSelect.map((item) => item.dates).flat();
				let uniqueDateAllUser = [...new Set(allData.map(JSON.stringify))];
				if (uniqueDateAllUser.length === 1) {
					return true;
					// if (this.dataOneSelect[0].dates.length === 1) {
					// 	return true;
					// }
					// return false;
				}
				return false;
			}
		},
		/**
		 * @return {String}
		 */
		rendStartLabel() {
			return isNil(this.startLabel) ? this.FormMSG(26, 'Start:') : this.startLabel;
		},

		/**
		 * @return {String}
		 */
		rendEndLabel() {
			return isNil(this.endLabel) ? this.FormMSG(28, 'End:') : this.endLabel;
		},

		/**
		 * @return {Boolean}
		 */
		hasReachLimitDuration() {
			const { start, end } = this.rangePayload;
			return this.isDiffReachLimit(start, end);
		},

		/**
		 * @return {Boolean}
		 */
		isGroundLimitReached() {
			const { start, date } = this.rangePayload;
			const _f = 'YYYY-MM-DD';
			return this.formatDate(start, _f) === this.formatDate(date, _f);
		},

		/**
		 * @return {Boolean}
		 */
		isADayAwayThanDate() {
			const { start, date } = this.rangePayload;
			return this.isDiffReachLimit(date, start);
		},

		/**
		 * @return {Boolean}
		 */
		isEndDateLimitedByStart() {
			if (this.endCanGoBeforeStartDate) return true;
			const { start, end } = this.rangePayload;
			const _f = 'YYYY-MM-DD';
			return this.formatDate(start, _f) === this.formatDate(end, _f);
		},

		/**
		 * @return {Boolean}
		 */
		isStartForwardDayLimitReached() {
			const { start, date } = this.rangePayload;
			const _d = this.formatDate(date, 'YYYY-MM-DD');
			const _s = this.formatDate(start, 'YYYY-MM-DD');

			if (_d === _s && this.startForwardDay === 0) return true;

			const diff = this.getDaysDiff(_s, _d, false);
			return diff >= this.startForwardDay;
		},
		/**
		 * @return {Boolean}
		 */
		isStartBackwardDayLimitReached() {
			const { start, date } = this.rangePayload;
			const _d = this.formatDate(date, 'YYYY-MM-DD');
			const _s = this.formatDate(start, 'YYYY-MM-DD');

			if (_d === _s && this.startBackwardDay === 0) return true;

			const diff = this.getDaysDiff(_s, _d, false);

			return diff > this.startBackwardDay;
		},
		/**
		 * @return {Boolean}
		 */
		isEndBackwardDayLimitReached() {
			const { end, date } = this.rangePayload;
			const diff = this.getDaysDiff(this.formatDate(date, 'YYYY-MM-DD'), this.formatDate(end, 'YYYY-MM-DD'));
			return end < date && diff >= this.endBackwardDay;
		},
		/**
		 * @return {Boolean}
		 */
		isEndForwardDayLimitReached() {
			const { end, date } = this.rangePayload;
			const diff = this.getDaysDiff(this.formatDate(date, 'YYYY-MM-DD'), this.formatDate(end, 'YYYY-MM-DD'));
			return diff >= this.endForwardDay;
		},

		// CHANGE DAYS DISABLED

		/**
		 * @return {Boolean}
		 */
		isStartSubtractDisabled() {
			if (this.hideEndDate) {
				return this.checkStartSubstractWhenHideEndDate;
			}

			return this.hasReachLimitDuration || this.isLimitedByInitDate || this.isStartBackwardDayLimitReached || this.disableSave;
		},
		/**
		 * @return {Boolean}
		 */
		isStartAddDisabled() {
			return this.isStartForwardDayLimitReached || this.disableSave;
		},
		/**
		 * @return {Boolean}
		 */
		isEndSubtractDisabled() {
			return this.isEndDateLimitedByStart || this.isEndBackwardDayLimitReached || this.disableSave;
		},
		/**
		 * @return {Boolean}
		 */
		isEndAddDisabled() {
			if (this.hideEndDate) {
				return this.checkStartAddWhenHideEndDate;
			}

			return this.disableSave || this.isEndForwardDayLimitReached;
		}
	},
	watch: {
		days: {
			handler(newVal) {
				return newVal;
			},
			deep: false,
			immediate: true
		},
		value: {
			/**
			 * @param {Object} payload
			 */
			handler(payload) {
				this.initRangePayload(payload);
			},
			deep: true,
			immediate: true
		},

		rangePayload: {
			/**
			 * @param {Object} payload
			 */
			handler(payload) {
				this.sendChanges(payload);
			},
			deep: true
		},

		'rangePayload.date': {
			handler(newVal) {
				if (this.hideEndDate && !_.isNil(newVal)) {
					if (this.startForwardDay > 0) {
						this.checkStartSubstractWhenHideEndDate = false;
					} else {
						this.checkStartSubstractWhenHideEndDate = true;
					}

					if (this.startBackwardDay > 0) {
						this.checkStartAddWhenHideEndDate = false;
					} else {
						this.checkStartAddWhenHideEndDate = true;
					}

					this.checkDataIfHideEndDate('start', 'subtract');
					this.checkDataIfHideEndDate('start', 'add');
				}
			},
			immediate: true
		}
	},
	created() {
		this.initRangePayload(this.value);
	},
	methods: {
		...mapFilters(['formatDate', 'diffInMins']),
		labelBeforeDate(val) {
			const date = new Date(this.rangePayload.date);
			const res = new Date(moment(this.rangePayload[val]).format('YYYY-MM-DD'));

			if (res.getTime() > date.getTime()) {
				return this.FormMSG(333, 'NEXT DAY');
			} else if (res.getTime() < date.getTime()) {
				return this.FormMSG(334, 'PREVIOUS DAY');
			} else if (res.getTime() === date.getTime()) {
				return this.FormMSG(335, 'SAME DAY');
			}
		},

		initRangePayload(payload) {
			if (isNil(this.value)) return;
			// const date = moment(new Date(payload.date)).format('YYYY-MM-DD')
			const date = this.formatDate(payload.date, 'YYYY-MM-DD');
			const start = this.formatInValidDate(date, this.value.start, 'start');
			const end = this.formatInValidDate(date, this.value.end, 'end');

			this.rangePayload = Object.assign(this.rangePayload, { date, start, end });
		},

		/**
		 * @param {String} position
		 * @param {String} type
		 */
		handleChangeDay(position, type) {
			if (!this.isValidPosition(position)) return;
			if (!this.isValidType(type)) return;

			const _dt = this.rangePayload[position];

			this.rangePayload[position] = moment(_dt)[type](1, 'days').format();

			this.$emit(`change-time-${position}`, this.rangePayload[position]);
			this.adaptEndDateFromStart(position, type, this.rangePayload[position]);

			if (this.hideEndDate === true) {
				this.checkDataIfHideEndDate(position, type);
			}
		},

		checkDataIfHideEndDate(position, type) {
			if (position === 'start' && type === 'subtract') {
				const date1 = moment(new Date(this.rangePayload.start.split('T')[0]));
				const date2 = moment(new Date(this.rangePayload.date));
				const duration = moment.duration(date1.diff(date2)).asDays();

				if (duration < 0) {
					if (Math.abs(Math.round(duration)) >= this.startForwardDay) {
						this.checkStartSubstractWhenHideEndDate = true;
					} else {
						this.checkStartSubstractWhenHideEndDate = false;
					}
				}

				this.checkStartAddWhenHideEndDate = false;
			}
			if (position === 'start' && type === 'add') {
				const date1 = moment(new Date(this.rangePayload.start.split('T')[0]));
				const date2 = moment(new Date(this.rangePayload.date));
				const duration = moment.duration(date1.diff(date2)).asDays();

				if (duration > 0) {
					if (Math.abs(Math.round(duration)) >= this.startBackwardDay) {
						this.checkStartAddWhenHideEndDate = true;
					} else {
						this.checkStartAddWhenHideEndDate = false;
					}
				}

				this.checkStartSubstractWhenHideEndDate = false;
			}
		},

		adaptEndDateFromStart(position, type, date) {
			if (position === 'start' && type === 'add' && !this.isEndDateLimitedByStart) {
				const _d = this.formatDate(date, 'YYYY-MM-DD');
				const _t = this.rendFormattedTime(this.rangePayload.end, 'end');
				this.rangePayload.end = moment(_d + ' ' + _t);
			}
		},

		/**
		 * @param {String} d1
		 * @param {String} d2
		 * @return {Number}
		 */
		getDaysDiff(d1, d2, sign = true) {
			const _d1 = moment(new Date(d1));
			const _d2 = moment(new Date(d2));
			const diff = moment.duration(_d1.diff(_d2)).asDays();
			return sign ? Math.abs(diff) : diff;
		},

		/**
		 * @param {String} d1
		 * @param {String} d2
		 * @return {Boolean}
		 */
		isDiffReachLimit(d1, d2) {
			var tmp = this.getDaysDiff(d1, d2) >= this.dayDurationLimit;
			return this.getDaysDiff(d1, d2) >= this.dayDurationLimit;
		},

		formatInValidDate(date, time, field) {
			if (time === '0001-01-01T00:00:00Z') {
				if (field === 'start') {
					this.startIsNull = true;
				} else if (field === 'end') {
					this.endIsNull = true;
				}

				return date + 'T00:00:00Z';
			}
			if (field === 'start') this.startIsNull = false;
			if (field === 'end') this.endIsNull = false;

			return !time.startsWith('T') ? time : date + time;
		},

		/**
		 * @param {Object} date
		 * @return {String}
		 */
		rendFormattedTime(date, field) {
			if (field === 'start' && this.startIsNull) {
				return '--:--';
			} else if (field === 'end' && this.endIsNull) {
				return '--:--';
			}
			return moment(new Date(date)).format('HH:mm');
		},

		/**
		 * @param {String} time
		 * @param {String} position
		 */
		handleTimeChange(time, position) {
			if (time === '' && position === 'start') {
				time = '00:00';
			}
			if (time === '' && position === 'end') {
				time = '23:59';
			}

			// time = this.checkInputTime(time);
			// console.log({ position })
			if (!this.isValidPosition(position)) return;
			const d = this.rangePayload[position];
			const _d = moment(d).format('YYYY-MM-DD');
			// console.log({ d, _d, time })
			//
			// this.rangePayload[position] = moment(`${_d} ${time}`).format();
			const parsedTime = moment(time, ['HH:mm', 'hh:mm A']).format('HH:mm');
			this.rangePayload[position] = moment(`${_d} ${parsedTime}`).format();

			this.checkWithSyncStartEnd(position);
			this.$emit(`change-time-${position}`, this.rangePayload[position]);
			// this.$emit(`change-time-${position}`, this.rangePayload[position])
			// console.log("handleTimeChange:", this.rangePayload[position])
		},

		checkWithSyncStartEnd(position) {
			if (this.syncStartEnd) {
				const START = new Date(this.value.start);
				const END = new Date(this.value.end);

				if (position === 'start') {
					if (START.getTime() === END.getTime()) {
						this.rangePayload.end = this.rangePayload.start;
						this.$emit(`change-time-end`, this.rangePayload.end);
					}
				}
			}
		},

		checkInputTime(time) {
			const timeSplited = time.split(':');
			return `${timeSplited[0]}:${timeSplited[1]}`;
		},

		/**
		 * @param {String|Date} date
		 */
		handleDateChange(date) {
			this.$emit('change-time-date', date);
		},

		/**
		 * @param {String} position
		 * @return {Boolean}
		 */
		isValidPosition(position) {
			return ['start', 'end'].includes(position);
		},

		/**
		 * @param {String} position
		 * @return {Boolean}
		 */
		isValidType(type) {
			return ['add', 'subtract'].includes(type);
		},

		/**
		 * @param {String} time
		 * @return {Boolean}
		 */
		isCloseToMidnight(time) {
			const lD = this.formatDate(this.rangePayload.start, 'YYYY-MM-DD');
			const limit = moment(`${lD} 23:59:00`);
			const diff = this.diffInMins(time, limit);
			return diff < 7 * 60; // if it's less than 7hours
		},

		/**
		 * @param {String} time
		 * @return {Boolean}
		 */
		handleStartDayCloseToMidnight(time) {
			if (!this.isCloseToMidnight(time)) return;
			this.handleChangeDay('end', 'add');
		},

		/**
		 * @param {Object} payload
		 * @returns {Emitter}
		 */
		sendChanges(payload) {
			this.$emit('change-range', [payload.start, payload.end]);
			this.$emit('change', payload);
		}
	}
};
</script>

<style lang="scss" scoped>
.input-date-number-iteration {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #e7f2ff;
	color: #225cbd;
	border-radius: 4px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	span.value {
		display: inline-block;
		text-align: center;
		width: 100%;
	}
}
</style>
